import React, { Component } from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase';
import firebaseui from 'firebaseui';
import $ from "jquery";
import Responsive from 'react-responsive';
import 'bootstrap/dist/js/bootstrap.js';

import pixylLogo from './PixylLogoNoTagline.svg';
//import naixtLogo from './naixt_logo.jpg';
import naixtLogo from './PixylLogoNoTagline.svg';

import 'bootstrap/dist/css/bootstrap.css';
import './main.css';

import viewerPixyl from "./viewer.js";

const Mobile = props => <Responsive {...props} maxWidth={767} />;
//const Mobile = function() {return false};
const Default = props => <Responsive {...props} minWidth={768} />;

// Initialize Firebase
const config = {
  apiKey: "AIzaSyAsg_0oAVdX7G49x3SM0N0ieywMaoNwkro",
  authDomain: "pixyl-ai.firebaseapp.com",
  databaseURL: "https://pixyl-ai.firebaseio.com",
  projectId: "pixyl-ai",
  storageBucket: "gs://pixyl-ai.appspot.com",
  messagingSenderId: "970559452781"
};
firebase.initializeApp(config);
const database = firebase.database();
const storage = firebase.storage();

let pathsViewer = {
  flair_axial: [],
  flair_sagittal: [],
  flair_coronal: []
}

function MobileMenu() {

  // set variables
  var $body = $('body');
  var $btnMenu = $('.btn-menu');
  // get the nav li elements from the 
  // desktop menu
  var navLiHTML = $('header nav ul').html();

  // create the mobile menu from the desktop li elements...
  var mobileNavHTML = $('<nav class="mobile-nav"><ul>' + navLiHTML + '</ul></nav>');

  // Add the mobile menu to the main element...
  $('main').prepend(mobileNavHTML);

  // select the newly created mobile menu
  var $mobileNav = $('.mobile-nav');

  // initialization method for the
  // MobileMenu class	
  this.init = function () {

    // measure height of menu in open state
    // then close the menu
    $body.addClass('show');
    var mobileNavOriginalHeight = $mobileNav.height();
    $body.removeClass('show');

    // Open all the menus
    // and measure the height of each
    $body.addClass('show');

    // Close the menu and the sub menus
    $body.removeClass('show');

    // Add click events to menu items
    $('.mobile-nav ul li #user-profile').click(
      // User profile
      function () {
        console.log('user profile');
        $btnMenu.click();
      });
    $('.mobile-nav ul li #sign-out').click(
      // Sign out
      function () {
        firebase.auth().signOut();
        $btnMenu.click();
      });

    // Click event handler for the mobile menu
    $btnMenu.click(function () {
      // check if body element has the
      // class show
      if ($body.hasClass('show')) {
        // menu is open...
        // remove any heights set on the mobile nav
        $mobileNav.removeAttr('style');
        // remove the "show" class from the body
        // element
        $body.removeClass('show');
      } else {
        // menu is closed...
        // set height of mobile menu to the open height
        $mobileNav.css('height', mobileNavOriginalHeight);
        // add the class "show" to the body element
        $body.addClass('show');
      } // end if menu is open...

    }); // end mobile menu click event handler

  } // end init()

} // end MobileMenu Constructor

class App extends Component {
  constructor(props) {
    super(props);
    this.handleAboutNext = this.handleAboutNext.bind(this);
    this.registerResponse = this.registerResponse.bind(this);
    this.state = {
      isShowAbout: true,
      isSignedIn: false,
      isShowInstruction: false,
      isShowStatistic: false,
      currentIteration: '001',
      currentSubjectIndex: 0,
      subjects: []
    };

    // Configure FirebaseUI.
    this.uiConfig = {
      // Popup signin flow rather than redirect flow.
      signInFlow: 'popup',
      signInOptions: [
        firebase.auth.EmailAuthProvider.PROVIDER_ID
      ],
      credentialHelper: firebaseui.auth.CredentialHelper.NONE,
      callbacks: {
        // Avoid redirects after sign-in.
        signInSuccessWithAuthResult: () => false
      }
    };
  }

  // Listen to the Firebase Auth state and set the local state.
  componentDidMount() {
    this.unregisterAuthObserver = firebase.auth().onAuthStateChanged((user) => {
      this.setState({ isSignedIn: !!user });
      if (user) {
        user.getIdTokenResult()
          .then((idTokenResult) => {
            if (!idTokenResult.claims.alloweduser) {
              firebase.auth().signOut();
            } else {
              let menu01 = new MobileMenu();
              menu01.init();
              this.showAbout();
              this.initState();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  }

  initState() {
    database.ref('/naixt-metadata').on('value', (snapshot) => {
      let metadata = snapshot.val();
      this.setState({
        currentIteration: metadata.currentIteration,
        currentSubjectIndex: metadata.currentSubjectIndex[firebase.auth().currentUser.uid] || 0,
        subjects: metadata.subjects,
        images: metadata.images
      });
      this.loadImages();
    });
  }

  handleAboutNext() {
    this.showProject();
  }

  registerResponse(answer, comment) {
    if (answer) {
      database.ref(`/ms_segmentation/iteration-${this.state.currentIteration}/${this.state.subjects[this.state.currentSubjectIndex]}/`).push({
        userEmail: firebase.auth().currentUser.email,
        answer: answer,
        comment: comment,
        date: Date.now()
      }, function (error) {
        if (error) {
          console.error(error);
        } else {
          database.ref('/naixt-metadata/currentSubjectIndex').transaction((response) => {
            if (response) {
              if (!response[firebase.auth().currentUser.uid]) {
                response[firebase.auth().currentUser.uid] = 1;
              } else {
                response[firebase.auth().currentUser.uid]++;
              }
            }
            return response;
          })
        }
      })
    }
  }

  //"axial", "sagittal", "coronal"
  populateViewer(modality, axis) {
    if (document.getElementById("seq3") === null) {
      this.initViewer(modality, axis, "400px", "400px", "seq1");
      document.querySelectorAll(".mobile-image-button").forEach(function (el) { el.classList.remove("is-focused") });
      document.getElementById("btn-" + axis).classList.add("is-focused");
    }
    else {
      var imgSize = Math.round(window.innerWidth / 4) + "px";
      this.initViewer(modality, "axial", imgSize, imgSize, "seq3-1", "left-img");
      this.initViewer(modality, "sagittal", imgSize, imgSize, "seq3-2", "mid-img");
      this.initViewer(modality, "coronal", imgSize, imgSize, "seq3-3", "right-img");

      /******************************/
      /* Parallel scroll management */
      /******************************/
      // a helper variable
      /*
      var timeout;
      $('#left-img, #mid-img, #right-img').on("scroll", function callback() {
        // clear the 'timeout' every 'scroll' event call
        // to prevent re-assign 'scroll' event to other element
        // before finished scrolling
        clearTimeout(timeout);

        // get the used elements
        var source = $(this);
        if (source.is("#left-img")) {
          // remove the callback from the other 'div' and set the 'scrollTop'
          $("#mid-img").off("scroll").scrollTop(source.scrollTop());
          $("#right-img").off("scroll").scrollTop(source.scrollTop());
          // create a new 'timeout' and reassign 'scroll' event
          // to other 'div' on 100ms after the last event call
          timeout = setTimeout(function () {
            $("#mid-img").on("scroll", callback);
            $("#right-img").on("scroll", callback);
          }, 100);
        } else if (source.is("#mid-img")) {
          // remove the callback from the other 'div' and set the 'scrollTop'
          $("#left-img").off("scroll").scrollTop(source.scrollTop());
          $("#right-img").off("scroll").scrollTop(source.scrollTop());
          // create a new 'timeout' and reassign 'scroll' event
          // to other 'div' on 100ms after the last event call
          timeout = setTimeout(function () {
            $("#left-img").on("scroll", callback);
            $("#right-img").on("scroll", callback);
          }, 100);
        } else {
          // remove the callback from the other 'div' and set the 'scrollTop'
          $("#left-img").off("scroll").scrollTop(source.scrollTop());
          $("#mid-img").off("scroll").scrollTop(source.scrollTop());
          // create a new 'timeout' and reassign 'scroll' event
          // to other 'div' on 100ms after the last event call
          timeout = setTimeout(function () {
            $("#left-img").on("scroll", callback);
            $("#mid-img").on("scroll", callback);
          }, 100);
        }
      });
      */
    }
  }

  initViewer(modality, axis, imgHeight, imgWidth, parentId, elmtId) {
    var stack = new viewerPixyl.ImageStack({
      images: pathsViewer[modality + "_" + axis],
      height: imgHeight,
      width: imgWidth,
      id: elmtId
    });

    var myNode = document.getElementById(parentId);
    while (myNode.lastChild) {
      myNode.removeChild(myNode.lastChild);
    }

    document.querySelector("#" + parentId).appendChild(stack);
  }

  // Make sure we un-register Firebase observers when the component unmounts.
  componentWillUnmount() {
    //this.unregisterAuthObserver();
  }

  showProject() {
    $("#image").show();
    this.setState({ isShowAbout: false, isShowInstruction: false, isShowStatistic: false });
  }

  loadImages() {
    if (this.state.currentSubjectIndex < this.state.subjects.length) {
      let that = this;
      const axis = ["axial", "sagittal", "coronal"];

      let paths = [];
      const subjectName = this.state.subjects[this.state.currentSubjectIndex];

      for (let a = 0; a < axis.length; ++a) {
        for (let i = this.state.images[axis[a]].min; i <= this.state.images[axis[a]].max; ++i) {
          const imageName = `${subjectName}_space-MNI148x184x154_label-lesion_dseg_bg-FLAIR_slice-${axis[a]}${(i / Math.pow(10, 3)).toFixed(3).substring(2)}.png`;
          paths.push(`comte-wmh01/it-${this.state.currentIteration}/${subjectName}/${imageName}`);
        }
      }
      Promise.all(paths.map(function (path) {
        return new Promise(function (resolve) {
          storage.ref(path).getDownloadURL().then(function (url) {
            resolve(url);
          });
        });
      })).then(function (urls) {
        for (let i = 0; i < axis.length; ++i) {
          pathsViewer["flair_" + axis[i]] = that._isAxis(urls, axis[i]);
        }
        that.populateViewer("flair", axis[0]);
      });
    }
  }

  _isAxis(arr, axis) {
    return arr.filter(function (el) {
      return el.includes(axis);
    })
  }

  showInstructions() {
    $("#image").hide();
    this.setState({ isShowAbout: false, isShowInstruction: true, isShowStatistic: false });
  }

  showStatistics() {
    $("#image").hide();
    this.setState({ isShowAbout: false, isShowInstruction: false, isShowStatistic: true });
  }

  showAbout() {
    $("#image").hide();
    this.setState({ isShowAbout: true, isShowInstruction: false, isShowStatistic: false });
  }

  closeSideBar() {
    document.getElementById("mySidebar").style.width = "0";
    document.getElementById("main").style.marginLeft = "0";
  }

  render() {
    if (!this.state.isSignedIn) {
      return (
        <div>
          <img id="pixyl-logo" src={pixylLogo} alt="PIXYL" />
          <img src={naixtLogo} className="naixt-logo" alt="NAIXT" />
          <StyledFirebaseAuth uiConfig={this.uiConfig} firebaseAuth={firebase.auth()} />
        </div>
      );
    }

    let iterationStat;
    if (this.state.currentSubjectIndex < this.state.subjects.length) {
      iterationStat = <span>{this.state.currentSubjectIndex} reviewed / {this.state.subjects.length} total subjects </span>;
    } else {
      iterationStat = <span>All subjects were rated!</span>;
    }

    return (
      <div>
        <nav className="navbar navbar-expand-lg navbar-light box-shadow ">
          <Default>
            <a href="#about" className="navbar-brand" onClick={() => {
              this.showAbout();
              document.getElementById("navInst").classList.remove('active');
              document.getElementById("navStat").classList.remove('active');
              document.getElementById("navProj").classList.remove('active');
              document.getElementById("navAbout").classList.add('active');
            }}>
              <img src={naixtLogo} className="naixt-logo" alt="NAIXT" />
            </a>

            <div className="topnav">
              <a id="navInst" href="#instructions" onClick={() => {
                this.showInstructions();
                document.getElementById("navProj").classList.remove('active');
                document.getElementById("navStat").classList.remove('active');
                document.getElementById("navAbout").classList.remove('active');
                document.getElementById("navInst").classList.add('active');
              }}>Instructions</a>
              <a id="navProj" href="#image" className="btn view-btn" onClick={() => {
                this.showProject();
                document.getElementById("navInst").classList.remove('active');
                document.getElementById("navStat").classList.remove('active');
                document.getElementById("navAbout").classList.remove('active');
                document.getElementById("navProj").classList.add('active');
              }}>Project</a>
              <a id="navStat" href="#stat" onClick={() => {
                this.showStatistics();
                document.getElementById("navInst").classList.remove('active');
                document.getElementById("navProj").classList.remove('active');
                document.getElementById("navAbout").classList.remove('active');
                document.getElementById("navStat").classList.add('active');
              }}>Statistics</a>
              <a id="navAbout" href="#about" onClick={() => {
                this.showAbout();
                document.getElementById("navInst").classList.remove('active');
                document.getElementById("navStat").classList.remove('active');
                document.getElementById("navProj").classList.remove('active');
                document.getElementById("navAbout").classList.add('active');
              }}>About</a>
            </div>
          </Default>

          <Mobile>
            <div className="topnav-mob">
              <div id="mySidebar" className="sidebar">
                <a href="#/" className="closebtn" onClick={() => {
                  this.closeSideBar();
                }}>×</a>
                <a href="#about" onClick={() => {
                  this.showAbout();
                  this.closeSideBar();
                }}>Home</a>
                <a href="#instructions" onClick={() => {
                  this.showInstructions();
                  this.closeSideBar();
                }}>Instructions</a>
                <a href="#image" id="navSideProj" className="view-btn" onClick={() => {
                  this.showProject();
                  this.closeSideBar();
                }}>Project</a>
                <a href="#stat" onClick={() => {
                  this.showStatistics();
                  this.closeSideBar();
                }}>Statistics</a>
                <a href="#about" onClick={() => {
                  this.showAbout();
                  this.closeSideBar();
                }}>About</a>
              </div>

              <div id="main">
                <button className="openbtn" onClick={() => {
                  document.getElementById("mySidebar").style.width = "250px";
                  document.getElementById("main").style.marginLeft = "250px";
                }}><img src={naixtLogo} className="naixt-logo" alt="NAIXT" /></button>
              </div>
            </div>
          </Mobile>

          <header>
            <nav>
              <button className="btn-menu">
                <span className="bar"></span>
                <span className="text">Menu</span>
              </button>
              <ul>
                <li><a href="#profile" id="user-profile">User profile</a></li>
                <li><a href="#signin" id="sign-out">Sign out</a></li>
              </ul>
            </nav>
          </header>
          <main role="main"></main>
        </nav>

        <About isShowAbout={this.state.isShowAbout} onAboutNext={this.handleAboutNext} />

        <Statistic isShowStatistic={this.state.isShowStatistic} />

        <Instruction isShowInstruction={this.state.isShowInstruction} />

        <div id="image">
          <div className="container">
            <div id="question" className="row justify-content-center">
              <h5>This is a MS patient, is this a lesion due to MS?</h5>
            </div>
            <div className="row justify-content-center">
              <p> Iteration #{this.state.currentIteration}: {iterationStat}</p>
            </div>
          </div>

          {this.state.currentSubjectIndex < this.state.subjects.length &&
            <div>
              <div className="row justify-content-center row-centered">
                <Default>
                  <div id="seq3">
                    <div id="seq3-1" className="square col-md-4 col-centered"></div>
                    <div id="seq3-2" className="square col-md-4 col-centered"></div>
                    <div id="seq3-3" className="square col-md-4 col-centered"></div>
                  </div>
                </Default>
                <Mobile>
                  <div id="seq1" className="square col-md-4 col-centered"></div>
                  <div className="square col-md-4 col-centered">
                    <div id="axis">
                      <button id="btn-axial" className="btn btn-lg rounded-0 mobile-image-button" onClick={() => {
                        this.populateViewer("flair", "axial");
                      }}>Ax</button>
                      <button id="btn-sagittal" className="btn btn-lg rounded-0 mobile-image-button" onClick={() => {
                        this.populateViewer("flair", "sagittal");
                      }}>Sa</button>
                      <button id="btn-coronal" className="btn btn-lg rounded-0 mobile-image-button" onClick={() => {
                        this.populateViewer("flair", "coronal");
                      }}>Co</button>
                    </div>
                  </div>
                </Mobile>
              </div>

              <ResponseButtons onResponse={this.registerResponse} />
            </div>
          }
        </div>

      </div>
    );
  }
}

class ResponseButtons extends Component {
  constructor(props) {
    super(props);
  }

  handleResponse(answer) {
    let comment = $('#comment').val();
    $('#comment').val('');
    this.props.onResponse(answer, comment);
  }

  render() {
    return (
      <div className="row no-gutters justify-content-center">
        <div className="col-2">
          <button id="no" type="button" className="btn btn-lg btn-block rounded-0" title="NO" onClick={() => {
            this.handleResponse('no');
          }}></button>
        </div>
        <div className="col-xs-2">
        </div>
        <div className="col-2">
          <button id="fatal" type="button" className="btn btn-lg btn-block rounded-0" title="Fatal" onClick={() => {
            this.handleResponse('fatal');
          }}></button>
        </div>
        <div className="col-xs-2"></div>
        <div className="col-2">
          <button id="yes" type="button" className="btn btn-lg btn-block rounded-0" title="YES" onClick={() => {
            this.handleResponse('yes');
          }}></button>
        </div>
        <div className="col-2">
          <textarea id="comment"></textarea>
        </div>
      </div>
    )
  }
}

class About extends Component {
  constructor(props) {
    super(props);
    this.handleAboutNext = this.handleAboutNext.bind(this);
  }

  handleAboutNext(e) {
    this.props.onAboutNext();
  }

  render() {
    return this.props.isShowAbout ? (
      <div id="about">
        <h4>About Pixyl</h4>
        <p>Data-driven Neuroimaging Analysis</p>
        <p>Thanks you for helping us to setup an expert consortium.</p>

        <div id="btn-next">
          <a id="view1" className="btn view-btn" role="button" onClick={this.handleAboutNext}>NEXT</a>
        </div>
      </div>
    ) : null;
  }
}

class Instruction extends Component {
  render() {
    return this.props.isShowInstruction ? (
      <div>
        <h4>Instructions for MS lesion identification</h4>
        <p className="txt-align-left">
          In this project you will ...<br /><br />
          Press the red cross if your answer is <span>YES</span>;<br />
          Press the green check if your answer is <span>NO</span>;<br />
          Press the question mark if you <span>can't say</span>.
          </p>
      </div>
    ) : null;
  }
}

class Statistic extends Component {
  render() {
    return this.props.isShowStatistic ? (
      <div>
        <h4>Statistics</h4>
        <h5>YOU</h5>
        <p className="txt-align-left">
          - You are <span>expert</span> in your group<br />
          - Your ranking is: <span>#1</span>, congratulations!<br />
          - Rated <span><span id="rate-count"></span></span> images<br />
          - <span>84%</span> similarity with other experts of your group
          </p>
        <h5>GROUP</h5>
        <p className="txt-align-left">
          - Rated <span><span id="rate-count"></span></span> images<br />
          - Total of <span>7</span> experts in the group
          </p>
      </div>
    ) : null;
  }
}

export default App;

